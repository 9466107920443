.button {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 36px;
    text-transform: lowercase;
    border: none;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;

    &:not(.button--reverse) {
        color: #040404;

        path {
            stroke: #000;
            fill: transparent
        }
    }

    svg {
        flex-shrink: 0;
        
        @media (min-width: 768px) {
            margin-left: 25px;
            width: 59px;
            height: 36px;
        }

        @media (max-width: 767px) {
            margin-left: 16px;
            width: 38px;
            height: 27px;
        }
    }

    &--reverse {
        color: #fff;
    }

    .move {
        transition: 200ms ease-in-out;
        transform: translateX(0);
    }

    &:hover {

        .move {
            animation: move 800ms ease-in-out;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    33% {
        transform: translateX(-8px);
    }

    66% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}