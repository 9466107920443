.view-contacts {
    display: flex;
    justify-content: space-between;

    @media (max-width: 599px) {
        flex-direction: column;
        margin-top: 60px;
    }

    &__list {
        flex-shrink: 0;

        @media (min-width: 600px) {
            padding-right: 60px;
        }

        @media (max-width: 599px) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
        }

        &__item {
            margin-bottom: 46px;
            max-width: 220px;

            @media (max-width: 599px) {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                padding: 0 15px;
            }
        }
    }

    a {
        color: #040404;
        transition: 200ms ease-in-out;

        &:hover {
            opacity: .65;
        }
    }

    .view-title {
        color: #040404;
        font-weight: 700;
        font-family: 'Palatino';
        
        @media (min-width: 991px) {
            font-size: 24px;
            margin-bottom: 26px;
		}
	
		@media (max-width: 990px) {
			font-size: 18px;
            margin-bottom: 20px;
			line-height: 24px;
		}
    }
}

.view-map {
    width: 100%;
}