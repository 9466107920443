.view-artists {

    &__form {
        position: relative;
        width: 100%;
        max-width: 370px;
        margin: 20px auto 50px auto;

        button {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 50px;
            height: 36px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: 200ms ease-in-out;

            &:hover {
                opacity: .65;
            }
        }

        input {
            padding-right: 64px;
        }

        .view-form__group {
            width: 100%;
            margin: 0;
        }
    }

    &__chars {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px 80px -8px;
        padding: 0;
        list-style: none;
        color: #646363;
        font-family: 'Palatino';
        font-size: 24px;
        font-weight: 700;

        li {
            padding: 0 8px;
            line-height: 36px;
            cursor: pointer;
            transition: 200ms ease-in-out;

            &:not(.is-active):hover {
                opacity: .65;
            }

            &.is-active {
                color: #000;
            }
        }
    }

    &__feedback {

        &__char {
            display: flex;
            align-items: flex-end;
            color: #050505;
            font-family: 'Palatino';
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 50px;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: #000;
                margin-left: 30px;
            }
        }

        &__list {
            position: relative;
            padding: 0;
            margin: 0;
            list-style: none;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #050505;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        margin-bottom: 24px;

        &__title {
            font-family: 'Palatino';
            font-size: 20px;
            font-weight: 700;
            color: #050505;
            transition: 200ms ease-in-out;

            &:hover {
                opacity: .65;
            }
        }

        &__location {
            color: #646363;
            font-size: 14px;
            font-weight: 400;
        }

        &__years {
            flex-shrink: 0;
            font-family: 'Palatino';
            font-size: 20px;
            font-weight: 700;
        }
    }
}