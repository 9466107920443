.checkout {

    .view-title--h2 {
        margin-top: 60px;
    }

    .panel-title {
        font-family: 'Palatino';
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
        color: #000;

        a {
            color: #000;
        }
    }

    .panel-body {
        height: 100%;
        margin: 50px 0;
        overflow: hidden;
    }

    .row {
        display: flex;
        padding-bottom: 50px;
        border-bottom: 1px solid #000;

        @media (max-width: 524px) {
            flex-direction: column;
        }
    }

    .col-sm-6 {

        @media (min-width: 525px) {
            width: 50%;
        }

        @media (max-width: 524px) {
            width: 100%;
        }
    }
    
    fieldset {
        padding: 0;
        border: none;
    }

    legend {
        font-family: 'Palatino';
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    .control-label {
        display: none;
    }

    .form-group {
        position: relative;
        margin: 0 0 50px 0;
    }

    .form-control:not(textarea) {
        font-family: 'Montserrat';
        display: block;
        width: 100%;
        max-width: 340px;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #000000;
        padding: 0 1px 7px 1px;
        border-radius: 0;

        @include placeholder(#000);
        @include placeholder-on-focus();
    }

    textarea.form-control {
        font-family: 'Montserrat';
        display: block;
        width: 100%;
        resize: none;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #000000;
        padding: 0 1px 7px 1px;
        border-radius: 0;

        @include placeholder(#000);
        @include placeholder-on-focus();
    }

    .text-danger {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        font-size: 10px;
        color: #FA3232;
        line-height: 10px;
    }

    a {
        color: #00A651;

        * {
            font-weight: 400;
        }
        
        &:hover {
            text-decoration: underline;
        }
    }

    .panel-collapse {
        height: 0;

        &.in {
            height: auto;
        }
    }

    .table-responsive {
        overflow-x: auto;
    }

    .table {
        width: 100%;

        @media (max-width: 767px) {
            font-size: 12px;
        }

        thead td {
            font-family: 'Palatino';
            font-weight: 600;
            font-size: 18px;
            vertical-align: top;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .view-shippings {
        margin: 0 0 40px 0;
    }
}

.success-page {
    padding: 120px 0;
}