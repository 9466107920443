.view-breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 300;
    color: #040404;
    text-transform: lowercase;

    @media (max-width: 990px) {
        margin-bottom: 20px;
    }

    a {
        color: #040404;
        transition: 200ms ease-in-out;

        &:hover {
            opacity: .65;
        }

        &::after {
            content: '-';
            display: inline-block;
            margin: 0 4px;
        }
    }
}