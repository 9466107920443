.view-catalog {
    display: flex;
    width: 100%;
    max-width: 1490px;
    margin: 0 auto 60px auto !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
    @media (max-width: 990px) {
      flex-direction: column;
      width: 100%;

      &::before {
        content: '';
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .65);
        opacity: 0;
        visibility: hidden;
      }
    }

    @media (min-width: 991px) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, .45);
        z-index: 9997;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
      }
  
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, .85);
        z-index: 9998;
        width: 120px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
        transform: translateX(-100%);
        animation: loader 1600ms linear;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        visibility: hidden;
        opacity: 0;
      }
    }

    &.is-preloader {
      position: relative;

      &::before, &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &__show-filter {
      @media (min-width: 991px) {
        display: none;
      }

      @media (max-width: 990px) {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Palatino';
        display: flex;
        align-items: center;
        line-height: 16px;
        margin-bottom: 40px;
        margin-left: auto;
        
        &__button {
          width: 26px;
          height: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 16px;
          margin-top: -4px;

          div {
            width: 100%;
            height: 2px;
            background: #05a551;
            border-radius: 1px;
          }
        }
      }
    }

    &__filter {
        width: 235px;
        margin-right: 100px;
        flex-shrink: 0;
        margin-bottom: -54px;

        @media (max-width: 990px) {
          position: fixed;
          top: 0;
          left: 0;
          background: #fff;
          z-index: 9999;
          padding: 24px 20px;
          margin: 0;
          height: 100%;
          overflow-y: auto;
          transition: 200ms ease-in-out;
          opacity: 0;
          visibility: hidden;

          .ocfilter {
            margin: 0 !important;
          }

          &.is-active {
            opacity: 1;
            visibility: visible;
            touch-action: none;
            
            ~ .view-ocfilter__background {
              opacity: 1;
              visibility: visible;
              display: block;
            }
          }
        }
    }
    @media (min-width: 991px) {
      .view-ocfilter__background {
        display: none;
      }
    }
    @media (max-width: 990px) {
      .view-ocfilter__background {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .45);
        width: 100%;
        height: 100%;
        transition: 200ms ease-in-out;
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }

    .view-products {
      min-width: unset;

      @media (min-width: 991px) {
        width: calc(100% - 280px);
      }
    }

    h1 span {
      display: none;
    }
}

.view-section:not(.view-catalog) ~ .view-pagination {
  .page-numbers {
    @media (min-width: 991px) {
      margin: 0 auto 112px auto;
    }

    @media (max-width: 990px) {
      margin: 0 0 60px 0;
    }
  }
}

.view-search {
  margin: 0 auto 60px auto !important;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
}

.page-numbers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    @media (min-width: 991px) {
      margin: 0 0 112px auto;
      width: calc(100% - 335px);
    }

    @media (max-width: 990px) {
      margin: 0 0 60px 0;
    }

    li {
        margin: 0 8px 18px 8px;
    }

    a {
        color: #00a651;
        transition: 100ms ease-in-out;

        &:hover {
            color: #040404;
        }
    }
}

.pf-help-title {
  display: flex;
  align-items: center;
  color: #060606;
  font-family: 'Palatino';
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
  margin-bottom: 32px;

  &__title {
    padding-right: 6px;
    margin-right: auto;
    font-size: 14px;
    font-weight: bold;
    color: #050505;
    text-transform: uppercase;
  }
}

.list-group-item {
    margin: 0 0 54px 0;
    overflow-x: hidden;
    padding-bottom: 10px;
}
.noUi-background {
    background: #979797;
    z-index: 2;
    padding: 0 5px;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 1px;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 1px;
}
.noUi-horizontal .noUi-handle {
  top: -4px;
  margin-left: -10px;
  width: 10px;
  height: 10px;
  background-color: #00a651;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 28px;
    height: 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.noUi-base > div:nth-child(1) .noUi-handle {
    margin-left: -5px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #979797;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #05a551;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  top: 14px;
}
/* Values;
 *
 */
.noUi-value {
  display: none;
}
.noUi-value:nth-child(1) {
  display:none;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  
  left: 0;
  width: 100%;
}
.noUi-pips-horizontal > div {
  color: #050505;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.noUi-pips-horizontal > div:not(:nth-child(2)):not(:nth-last-child(1)) {
  display: none !important;
}
.noUi-pips-horizontal > div:nth-child(2) {
    transform: translateX(0);
}
.noUi-pips-horizontal > div:nth-last-child(1) {
    transform: translateX(-100%);
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  display:none
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

@import '../components/filter';

.ocfilter {
  margin-left: 20px;

  @media (max-width: 990px) {
    position: relative;

    &.is-preloader {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        background-color: rgba(255, 255, 255, .65);
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes loader {
  0% {
    transform: translateX(-100%);
  }

  25% {
    transform: translateX(calc(100vw + 100%));
  }

  100% {
    transform: translateX(calc(100vw + 100%));
  }
}