.about {

    h2 {
        margin: 0 0 46px 0;
        color: #050505;
        font-family: 'Palatino';
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
    }

    &__top {
        display: flex;
        
        @media (max-width: 1100px) {
            flex-direction: column;
        }

        img {
            flex-shrink: 0;
            height: 400px;
            width: auto;
            margin: -117px 0 0 100px;

            @media (max-width: 1100px) {
                margin: 30px auto;
            }

            @media (max-width: 500px) {
                width: 100%;
                max-width: 320px;
                height: auto;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 120px 0 -50px 0;

        @media (max-width: 880px) {
            flex-direction: column;
        }
    }

    &__item {
        width: calc(50% - 25px);
        padding: 80px 50px 160px 50px;
        margin: 0 0 50px 0;
        background-size: 130% !important;

        @media (max-width: 880px) {
            width: 100%;
            background-size: cover !important;
        }

        &:nth-child(1) {
            @media (min-width: 881px) {
                background: url('../image/a1a/a2.svg') center top no-repeat;
            }
            @media (max-width: 880px) {
                background: url('../image/a1a/a2.svg') center 75% no-repeat;
            }
        }

        &:nth-child(2) {
            @media (min-width: 881px) {
                background: url('../image/a1a/a3.svg') center top no-repeat;
            }
            @media (max-width: 880px) {
                background: url('../image/a1a/a3.svg') center 75% no-repeat;
            }
        }
        
        &:nth-child(3) {
            @media (min-width: 881px) {
                background: url('../image/a1a/a4.svg') center top no-repeat;
            }
            @media (max-width: 880px) {
                background: url('../image/a1a/a4.svg') center 75% no-repeat;
            }
        }

        &:nth-child(4) {
            @media (min-width: 881px) {
                background: url('../image/a1a/a5.svg') center top no-repeat;
            }
            @media (max-width: 880px) {
                background: url('../image/a1a/a5.svg') center 75% no-repeat;
            }
        }

        a {
            color: #050505;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}