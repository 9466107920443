/**
 * Colors
 */
$white: #fff;
$black: #000;
$gray: #333;
$green: #00A651;
$hColor: #E8E8E8;

/**
 *  Fonts
 */
$fTitle: 'Palatino';
$fTitleRoman: 'Palatino';
$roboto: 'Montserrat';
$calibri: 'Montserrat';


/**
 * Helpers
 */
$easing: cubic-bezier(0.455, 0.03, 0.515, 0.955);