.container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 15px;
}

.view-section {
    @media (min-width: 991px) {
        padding: 120px 0 40px 0;
    }

    @media (max-width: 990px) {
        padding: 60px 0 40px 0;
    }
}

.container > .view-section {

    &:last-child {
        
        @media (min-width: 991px) {
            padding: 60px 0 120px 0;
        }
    
        @media (max-width: 990px) {
            padding: 40px 0 60px 0;
        }
    }
}