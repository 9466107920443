.view-footer {
    background-color: #f7f7f7;
    
    @media (min-width: 768px) {
        padding: 124px 0 110px 0;
    }

    @media (max-width: 767px) {
        padding: 60px 0 0 0;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        max-width: 980px;

        @media (max-width: 767px) {
            &.desktop {
                display: none;
            }
        }

        @media (min-width: 768px) {
            &.mobile {
                display: none;
            }
        }

        @media (max-width: 767px) {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            p {
                margin-top: 0;
            }

            svg {
                width: 32px;
                height: 32px;
                margin-top: 0 !important;
                margin-bottom: 12px;
                fill: #040404;
            }

            .location {
                width: 100%;
                max-width: 200px;
                margin-bottom: 32px;
                text-align: center;
            }

            .phone, .email {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 50%;
            }

            .partners {
                display: flex;
                width: 100%;
                max-width: 100% !important;
                align-items: center;
                justify-content: space-evenly;
                margin: 32px 0;

                a:nth-child(2) {
                    margin-top: -5px;
                }
            }

            .payments {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 48px;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    a {
        font-weight: normal;
        color: #040404;
        text-decoration: none;
        transition: 100ms ease-in-out;

        &:hover {
            opacity: .65;
        }
    }

    img {
        display: inline-block;
        @media (min-width: 768px) {
            max-width: 150px;
        }

        @media (max-width: 767px) {
            max-width: 120px;
        }
    }

    svg {
        margin-top: 30px;
        width: 140px;
    }
}