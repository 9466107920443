.view-underheader {
    height: 30px;
    background-color: #f7f7f7;
    font-family: 'Palatino';
    font-size: 14px;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &__social,
    .view-languages {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 -12px;
        list-style: none;

        li {
            margin: 0 12px;
        }
    }

    .view-languages {
        text-transform: lowercase;

        .is-active a {
            opacity: .75;
        }
    }

    a {
        color: #040404;
        text-decoration: none;
        transition: 200ms ease-in-out;

        &:hover {
            opacity: .45;
        }
    }
}