.view-navigation {
    font-family: 'Palatino';
    font-weight: 700;

    @media (min-width: 1240px) {
        font-size: 18px;
        margin: 0 48px;
    }

    @media (max-width: 1239px) {
        font-size: 16px;
        margin: 0 0 0 auto;
    }

    @media (min-width: 991px) {
        ul {
            display: flex;
            align-items: center;
            margin: 0 -12px;
            padding: 0;
            list-style: none;
        }
    
        li {
            position: relative;
            margin: 0 12px;
    
            &.is-active {
    
                path {
                    stroke-dashoffset: 0;
                }
            }
        }
    
        a {
            position: relative;
            color: #000;
            text-decoration: none;
            white-space: nowrap;
    
            &:hover {
    
                path {
                    animation: dash 1s linear alternate;
                    animation-fill-mode: forwards;
                }
            }
        }
    
        path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
        }
    
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 44px);
            transform: translate(-50%, -50%);
        }
    }

    @media (max-width: 990px) {
        display: none;
    }
}

@keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
}