.view-header-image {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 235px);
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120%;
        height: 120%;
        object-fit: cover;
        object-position: center;
        transform: translate(-50%, -50%);
    }

    &::before {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &__content {
        z-index: 3;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 964px;
        height: 100%;
        padding: 0 15px;
        margin: 0 auto;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        color: #fff;
        
        @media (min-width: 991px) {
            font-size: 18px;
        }

        @media (max-width: 990px) {
            font-size: 14px;
        }
    }

    p {
        margin: 0 0 30px 0;
    }

    .button {
        margin-left: auto;
    }
}

.view-types {
    display: grid;

    @media (min-width: 1240px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: calc(50vh - 15px) calc(50vh - 15px);
        grid-gap: 30px;
    }

    @media (max-width: 1239px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 280px 280px 280px;
        grid-gap: 16px;
        grid-template-areas: 
        "a a b b"
        "a a c c"
        "d f g g";
    }

    @media (max-width: 767px) and (min-width: 525px) {
        grid-template-areas: 
        "a a b b"
        "c c d d"
        "f f g g";
    }

    @media (max-width: 524px) {
        grid-template-columns: 1fr;
        grid-template-rows: 180px 180px 180px 180px 180px 180px;
        grid-template-areas: 
        "a"
        "b"
        "c"
        "d"
        "f"
        "g";
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-right: 15px;

        @media (min-width: 1240px) {
            padding-top: 120px;
            padding-left: 40px;
        }

        @media (max-width: 1239px) {
            padding-top: 60px;
            padding-left: 20px;
        }

        &:hover {

            .move {
                animation: move 800ms ease-in-out;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
            }
        }

        img {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media (min-width: 1240px) {
            &:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 3;
                padding-left: 100px;
            }
    
            &:nth-child(3) {
                grid-column-start: 3;
                grid-column-end: 5;
            }
        }

        @media (max-width: 1239px) {
            &:nth-child(1) { grid-area: a; }
            &:nth-child(2) { grid-area: b; }
            &:nth-child(3) { grid-area: c; }
            &:nth-child(4) { grid-area: d; }
            &:nth-child(5) { grid-area: f; }
            &:nth-child(6) { grid-area: g; }
        }

        @media (min-height: 700px) and (min-width: 1240px) {

            &:nth-child(5) {
                padding-top: 200px;
            }
        }

        &__title {
            color: #000000;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            
            @media (min-width: 1240px) {
                font-size: 38px;
                margin-bottom: 30px;
            }
    
            @media (max-width: 1239px) {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }
    }
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    33% {
        transform: translateX(-8px);
    }

    66% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

.view-make-req {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 764px;
    margin: 0 auto;
    padding: 130px 15px 100px 15px;

    &::after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        opacity: .3;
        transform: translateX(-50%);
    }

    &__image {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1300px;
        max-width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        opacity: .1;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .button {
        margin-left: auto;
        font-weight: 600;
        color: #000;
        
        @media (min-width: 650px) {
            font-size: 30px;
        }

        @media (max-width: 649px) {
            font-size: 24px;
            line-height: 1;
        }
    }

    &__title {
        margin-bottom: 34px;
        color: #000000;
        font-weight: 600;
        
        @media (min-width: 650px) {
            font-size: 20px;
        }

        @media (max-width: 649px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}