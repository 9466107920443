.view-header {

    &__wrapper {
        z-index: 10;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-top: 50px;
        padding-bottom: 58px;
    }

    &__cart {
        position: relative;
        height: 18px;
        margin: 0 16px 0 auto;
        cursor: pointer;
        transition: 200ms ease-in-out;

        &:hover {
            opacity: .65;
        }

        &.is-active {

            path {
                fill: #00a651;
            }
        }

        &__counter {
            position: absolute;
            right: 0;
            top: -10px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            color: #00a651;
        }
    }

    &__search {
        height: 18px;
        cursor: pointer;

        > input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;

            &:checked ~ .view-header__search__field {
                opacity: 1;
                visibility: visible;
            }
        }

        label {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 200ms ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: .65;
            }
        }
        
        &__field {
            z-index: 2;
            position: absolute;
            top: 100%;
            right: 0;
            width: 100%;
            max-width: 280px;
            height: 32px;
            transform: translateY(-140%);
            opacity: 0;
            visibility: hidden;
            transition: 200ms ease-in-out;

            @media (max-width: 1190px) {
                right: 16px;
            }
        }

        button {
            z-index: 3;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50px;
            border: none;
            background-color: transparent;
            transition: 200ms ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: .65;
            }
        }

        .form-control {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 66px 0 16px;
            background-color: #fff;
            border: 1px solid #000;
            color: #000000;
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 400;
            border-radius: 0;

            @media (max-width: 990px) {
                border-color: #9b9696;
            }

            @include placeholder(#000);
            @include placeholder-on-focus();
        }
    }
}