.view-product {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0;
    
    @media (min-width: 991px) {
        margin-top: 60px;
    }

    @media (max-width: 990px) {
        flex-direction: column;
    }

    &__wallview {
        margin-top: 20px;
    }

    .view-products__item__no-price {
        margin: 0;
        padding: 60px 0 10px 0;
        font-size: 16px !important;
    }

    h1 {
        text-transform: none;
        margin-bottom: 20px;
    }

    .mg-brand-wrapper-product {

        a {
            color: #919090;
            font-size: 14px;
            transition: 200ms ease-in-out;

            &:hover {
                color: #00a651;
            }
        }
    }
    
    table {
        margin-top: 20px;
        font-size: 14px;

        th {
            color: #646363;
            text-align: left;
            font-family: 'Palatino';
            font-weight: 500;
            padding: 6px 0;
        }

        td {
            color: #919090;
            padding: 6px 0 6px 28px;
        }

        p {
            margin: 0;
            padding: 0;
        }
    }

    .view-delivery-cost {
        margin: 30px 0;
    }

    &__thumbnail {
        @media (min-width: 991px) {
            width: 60%;
            margin: 0 25px 60px -25px;
            will-change: min-height;
        }

        @media (max-width: 990px) {
            width: 100%;
            margin: 0 0 60px 0;
            height: auto !important;
        }

        &:not(.is-affixed) {
            display: flex;
            flex-wrap: wrap;
        }

        > div:not(.inner-wrapper-sticky) {
            display: flex;
            padding: 30px;
            background-color: rgba(247,247,247,0.5);
            
            @media (min-width: 991px) {
                margin: 0 25px 50px 25px
            }

            @media (max-width: 990px) {
                margin: 0 0 24px 0;
                justify-content: space-between;
            }
            
            &:nth-child(1) {
                max-height: 70vh;

                @media (max-width: 990px) {
                    margin: 0 0 24px 0
                }
            }

            &:not(:nth-child(1)) {
                @media (min-width: 991px) {
                    width: calc(50% - 50px);
                    height: 360px;
                }

                @media (max-width: 990px) {
                    width: calc(50% - 7.5px);
                    height: 120px;
                    padding: 8px;
                }
            }
        }

        .inner-wrapper-sticky {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            transform: translate(0, 0); /* For browsers don't support translate3d. */
            transform: translate3d(0, 0, 0);
            will-change: position, transform;

            @media (max-width: 990px) {
                position: static !important;
                transform: none !important;
                justify-content: space-between;
            }

            > div {
                display: flex;
                background-color: rgba(247,247,247,0.5);
    
                @media (min-width: 991px) {
                    margin: 0 25px 50px 25px;
                    padding: 30px;
                }
    
                @media (max-width: 990px) {
                    margin: 0 0 24px 0
                }
                
                &:nth-child(1) {
                    max-height: 70vh;

                    @media (max-width: 990px) {
                        margin: 0 0 24px 0
                    }
                }
    
                &:not(:nth-child(1)) {
                    @media (min-width: 991px) {
                        width: calc(50% - 50px);
                        height: 360px;
                    }
    
                    @media (max-width: 990px) {
                        width: calc(50% - 7.5px);
                        height: 120px;
                        padding: 8px;
                    }
                }
            }
        }


        a {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: auto;
        }

        img {
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    
    &__info {
        position: relative;
        right: 0;
        top: 0;
        
        @media (min-width: 991px) {
            width: 40%;
        }

        @media (max-width: 990px) {
            width: 100%;
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
        }
        
        .view-form__group {
            width: 100%;
        }

        .view-form__select {
            width: 100%;
            max-width: 320px;
        }

        &__price {
            margin-bottom: 0;
            font-weight: 800;
            
            @media (min-width: 991px) {
                font-size: 24px;
            }

            @media (max-width: 990px) {
                font-size: 18px;
            }

            &__wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 380px;
                padding: 60px 0 10px 0;
            }
        }
    }

    .single_add_to_cart_button,
    .added_to_cart,
    .single-make-request {
        position: relative;
        display: inline-flex;
        margin: 0 0 0 40px;
        color: #00a651;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #fff;
        transition: 200ms ease-in-out;
        cursor: pointer;

        path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 44px);
            height: auto;
            transform: translate(-50%, -50%);
        }

        &:hover {

            path {
                animation: dash 1s linear alternate;
                animation-fill-mode: forwards;
            }
        }
    }

    .single-make-request {
        margin: 12px 0 10px auto;
    }

    .shop_attributes ~ .single-make-request {
        margin: 60px 0 10px 0;
    }
}

@keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
}

.view-tabs {

    &__head {
        display: flex;
        font-family: 'Paltino';
        font-weight: bold;
        flex-wrap: wrap;
        
        @media (min-width: 680px) {
            font-size: 20px;
            margin: 80px -30px 50px -30px;
        }
        
        @media (max-width: 679px) {
            font-size: 18px;
            margin: 40px -15px 25px -15px;
        }


        &__item {
            @media (min-width: 680px) {
                margin: 0 30px 15px 30px;
            }
            
            @media (max-width: 679px) {
                margin: 0 15px 15px 15px;
            }
            transition: 200ms ease-in-out;

            &:not(.is-active) {
                cursor: pointer;
                color: #919090;
            }

            &:hover {
                color: #000;
            }
        }
    }

    &__body {

        &__item {
            color: #444444;
            font-size: 14px;

            &:not(:first-child) {
                display: none;
            }

            b {
                font-family: 600;
            }

            > b:first-child,
            > strong:first-child {
                display: block;
                font-family: 'Palatino';
                font-size: 16px;
                color: #000;
            }

            > b:not(:first-child), 
            > strong:not(:first-child) {
                color: #444444;
                font-family: 'Palatino';
                font-size: 16px;
            }

            p {
                > b:first-child,
                > strong:first-child {
                    display: block;
                    font-family: 'Palatino';
                    font-size: 16px;
                    color: #000;
                }

                > b:not(:first-child), 
                > strong:not(:first-child) {
                    color: #444444;
                    font-family: 'Palatino';
                    font-size: 16px;
                }
            }
        }
    }
}

.fr-caption {

    ul {
        list-style: none;
        padding: 0 15px;
        column-count: 2;
        font-family: 'Montserrat';
    }
}