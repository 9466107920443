.view-news {

    &__wrapper {
        padding-top: 30px;

        ~ .view-pagination {

            .page-numbers {
                margin: 70px auto 120px auto;
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 768px) {
            margin: 0 -25px -50px -25px;
        }
        @media (max-width: 767px) {
            margin: 0 0 -40px 0;
        }

        &__item {
            @media (min-width: 768px) {
                width: calc(50% - 50px);
                margin: 0 25px 50px 25px;
                padding: 50px 50px 120px 50px;
            }
            @media (max-width: 767px) {
                width: 100%;
                margin: 0 0 40px 0;
                padding: 24px 24px 60px 24px;
            }

            &:nth-child(1) {
                background-color: #98AFAD;
            }

            &:nth-child(2) {
                background-color: #C3D9D8;
            }

            &:nth-child(3) {
                background-color: #D0D7CC;
            }

            &:nth-child(4) {
                background-color: #E7E4DB;
            }

            &:nth-child(5) {
                background-color: #E7C6B5;
            }

            &:nth-child(6) {
                background-color: #F0A562;
            }

            &__thumbnail {
                display: block;
                overflow: hidden;
                
                @media (min-width: 768px) {
                    height: 400px;
                    margin: 0 0 50px 0;
                }
                @media (max-width: 767px) {
                    height: 300px;
                    margin: 0 0 26px 0;
                }


                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &__title {
                font-family: 'Palatino';
                font-weight: bold;
                color: #050505;
                text-decoration: none;
                transition: 200ms ease-in-out;
                
                @media (min-width: 768px) {
                    font-size: 24px;
                    line-height: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 18px;
                    line-height: 24px;
                }

                &:hover {
                    opacity: .45;
                }
            }

            &__caption {
                @media (min-width: 768px) {
                    margin: 20px 0 50px 0;
                }
                @media (max-width: 767px) {
                    margin: 16px 0 40px 0;
                }
                color: #050505;
            }
        }
    }
}

.view-post {
    position: relative;
    margin: 60px 0 120px 0;

    &::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: -50%;
        
        @media (min-width: 768px) {
            width: 200vw;
            height: 460px;
        }

        @media (max-width: 767px) {
            width: 200vw;
            height: 180px;
        }
    }

    &--0 {
        &::before {
            background-color: #D0D7CC;
        }
    }

    &--1 {
        &::before {
            background-color: #C3D9D8;
        }
    }

    &--2 {
        &::before {
            background-color: #C3D9D8;
        }
    }

    &--3 {
        &::before {
            background-color: #E7E4DB;
        }
    }

    &--4 {
        &::before {
            background-color: #E7C6B5;
        }
    }

    &--5 {
        &::before {
            background-color: #F0A562;
        }
    }

    &__title {
        margin: 0;
        padding: 80px 0 50px 0;
        font-family: 'Palatino';
        font-size: 24px;
        font-weight: bold;
        color: #050505;
        text-align: center;
    }

    &__image {
        display: block;
        margin: 0 auto 50px auto;
        width: auto;
        
        @media (min-width: 768px) {
            height: 75vh;
            min-height: 400px;
            max-height: 738px;
        }

        @media (max-width: 767px) {
            height: auto;
            width: 100%;
        }
    }
}