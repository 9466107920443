.view-logotype {
    flex-shrink: 0;

    @media (min-width: 1240px) {
        width: 182px;
        height: 97px;
    }

    @media (max-width: 1239px) {
        width: 122px;
        height: 64px;
    }
}