.view-mmenu {
    @media (min-width: 991px) {
        display: none;
    }

    @media (max-width: 990px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background: #fff;
        width: 260px;
        height: 100vh;
        height: -webkit-fill-available;
        overflow-y: auto;
        overflow-x: hidden;
        transition: 200ms ease-in-out;
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;
        font-family: 'Palatino';

        hr {
            width: 100%;
        }

        ul {
            padding: 20px 24px;
            margin: 0;
            list-style: none;
        }

        li {
            margin: 10px 0;
        }

        a {
            color: #040404;
            font-weight: 400;
            font-family: 'Palatino';
        }

        .view-mmenu__navigation {
            font-size: 18px;
            margin-top: auto;
            margin-bottom: auto;
        }

        .view-languages {
            display: flex;
            margin: 0 -10px;
            padding: 8px 24px;

            li {
                margin: 0 10px;
            }
        }

        .view-aside__contacts, .view-social {
            padding: 8px 24px;
            font-weight: 600;
        }

        &__background {
            position: fixed;
            z-index: 999;
            background: rgba(0, 0, 0, .45);
            width: 100%;
            height: 100%;
            transition: 200ms ease-in-out;
            visibility: hidden;
            opacity: 0;
            touch-action: none;
        }
    }
}

body.is-mmenu {

    .view-mmenu {
        opacity: 1;
        visibility: visible;

        &__background {
            opacity: 1;
            visibility: visible;
        }
    }
}

.view-burger {
    @media (max-width: 990px) {
        display: flex;
        flex-direction: column;
        width: 24px;
        height: 16px;
        margin-left: 16px;
        justify-content: space-between;

        div {
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background: #000;
        }
    }
}