.view-products {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    height: fit-content;
    
    @media (min-width: 991px) {
        margin: 0 -50px -120px -50px;
    }

    @media (max-width: 990px) {
        margin: 0 -24px -60px -24px;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #060606;
        
        @media (min-width: 991px) {
            width: calc(100% / 3);
            margin-bottom: 120px;
            padding: 0 25px;
        }
    
        @media (max-width: 990px) {
            width: 50%;
            margin-bottom: 60px;
            padding: 0 16px;
        }

        &--4 {
            width: 25%;
        }

        a {
            display: flex;
            flex-direction: column;
        }

        &__image__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0 0 30px 0;
            background-color: rgba(247, 247, 247, .5);
            flex-shrink: 0;
            
            @media (min-width: 991px) {
                height: 360px;
                padding: 30px;
            }
        
            @media (max-width: 990px) and (min-width: 768px) {
                height: 280px;
                padding: 16px;
            }

            @media (max-width: 767px) {
                height: 180px;
                padding: 16px;
            }

            img {
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }

        &__title {
            margin-bottom: 6px;
            color: #060606;
            font-weight: 600;
            transition: 200ms ease-in-out;

            @media (min-width: 991px) {
                font-size: 18px;

                &:hover {
                    opacity: .65;
                }
            }
        
            @media (max-width: 990px) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        &__artist {
            color: #060606;
            font-weight: 200;
            transition: 200ms ease-in-out;
            margin-top: auto;
            margin-bottom: 7px;

            @media (min-width: 991px) {
                font-size: 14px;

                &:hover {
                    opacity: .65;
                }
            }
        
            @media (max-width: 990px) {
                font-size: 12px;
            }
        }

        &__size {
            font-weight: 300;

            @media (min-width: 991px) {
                font-size: 14px;
            }
        
            @media (max-width: 990px) {
                font-size: 12px;
            }
        }

        &__price {
            margin-top: 21px;
            font-size: 18px;
            font-weight: 600;
        }

        &__no-price {
            color: #f61212;
            font-weight: 600;
            text-transform: uppercase;
            
            @media (min-width: 600px) {
                margin-top: 21px;
                font-size: 16px;
            }
            
            @media (max-width: 599px) {
                margin-top: 15px;
                font-size: 12px;
            }
        }

        &__request {
            display: inline-block;
            width: max-content;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            color: #646363;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            transition: 200ms ease-in-out;

            @media (min-width: 600px) {
                margin-top: 21px;
                font-size: 16px;

                path {
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                }
        
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: calc(100% + 44px);
                    transform: translate(-50%, -50%);
                }
        
                &:hover {
                    color: #00a651;
        
                    path {
                        animation: dash 1s linear alternate;
                        animation-fill-mode: forwards;
                    }
                }
            }

            @media (max-width: 599px) {
                margin-top: 15px;
                font-size: 12px;

                svg {
                    display: none;
                }
            }
        }

        &__reserved {
            color: #f6cf12;
            font-weight: 600;
            text-transform: uppercase;

            @media (min-width: 600px) {
                margin-top: 21px;
                font-size: 16px;
            }
            
            @media (max-width: 599px) {
                margin-top: 15px;
                font-size: 12px;
            }
        }

        &__auction {
            display: inline-block;
            width: max-content;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            color: #12acf6;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            transition: 200ms ease-in-out;

            @media (min-width: 600px) {
                margin-top: 21px;
                font-size: 16px;

                path {
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                }
        
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: calc(100% + 44px);
                    transform: translate(-50%, -50%);
                }
        
                &:hover {
        
                    path {
                        animation: dash 1s linear alternate;
                        animation-fill-mode: forwards;
                    }
                }
            }
            
            @media (max-width: 599px) {
                margin-top: 15px;
                font-size: 12px;

                svg {
                    display: none;
                }
            }
        }

        @media (min-width: 600px) {
            &:hover {

                .view-products__buy {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__buy {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: inline-flex;
        margin: 0 auto;
        color: #00a651;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: 200ms ease-in-out;
        transform: translateX(-50%);
        cursor: pointer;

        @media (min-width: 600px) {
            font-size: 16px;

            path {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
            }
    
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                width: calc(100% + 44px);
                transform: translate(-50%, -50%);
            }
    
            &:hover {
    
                path {
                    animation: dash 1s linear alternate;
                    animation-fill-mode: forwards;
                }
            }
        }
        
        @media (max-width: 599px) {
            font-size: 12px;

            svg {
                display: none;
            }
        }
    }
}

@keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
}