.view-form {

    &__group {
        display: inline-block;
        margin-bottom: 30px;

        &-radio {
            font-family: 'Montserrat';
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px 20px -10px;
        }
    }

    &__select {
        font-family: 'Montserrat';
        min-width: 240px;
        display: block;
        color: #050505;
        border-radius: 0;
        width: 100%;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #000000;
        padding: 0 1px 7px 1px;
    }

    &__checkbox {
        margin: 0 10px 10px 10px;

        label {
            font-family: 'Montserrat';
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;

            &:checked ~ pre::before {
                opacity: 1;
            }
        }

        pre {
            position: relative;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            border: 2px solid #050505;
            border-radius: 20px;
            margin: 2px 8px 0 0;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                background-color: #050505;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: 200ms ease-in-out;
            }
        }
    }

    &__field {
        font-family: 'Montserrat';
        display: block;
        width: 100%;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #000000;
        border-radius: 0;
        padding: 0 1px 7px 1px;

        @include placeholder(#000);
        @include placeholder-on-focus();
    }

    &__file {
        input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }

    &__textarea {
        font-family: 'Montserrat';
        display: block;
        width: 100%;
        height: 26.4px;
        background-color: #fff;
        border: none;
        border-bottom: 1px solid #000000;
        padding: 0 1px 7px 1px;
        margin-bottom: 30px;
        margin-top: 60px;
        border-radius: 0;
        resize: none;

        @include placeholder(#000);
        @include placeholder-on-focus();
    }
}


body {

    &:not(.is-popup) {
        #feedback-echo {
            left: 50%;
        }
    }

    &.is-popup {
        #feedback-echo {
            left: 50%;
        }
    }
}

#feedback-echo {
    position: fixed;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(560);
    max-width: 100%;
    height: rem(200);
    padding: 16px;
    transform: translate(-50%, -50%);
    border: 1px solid $green;
    background: rgba(255, 255, 255, 0.95);
    color: $black;
    font-family: $fTitle;
    font-size: rem(28);
    line-height: 1;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 300ms $easing;
    z-index: 999999;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}