// @import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
// @font-face{font-family:"DidotLT";font-display:swap;src:url(../fonts/DidotLT.woff) format("woff")}
// @font-face{font-family:"DidotLT-Roman";font-display:swap;src:url(../fonts/DidotLT-Roman.woff) format("woff")}
@font-face {
	font-family: 'Palatino';
	src: url('../fonts/palab.woff2') format('woff2')
}


html {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	color: #040404;
	
	@media (min-width: 991px) {
		font-size: 16px;
		line-height: 24px;
	}

	@media (max-width: 990px) {
		font-size: 14px;
		line-height: 18px;
	}
}

/**
 * Text styles
 */
.link {
	color: #00A651;

	&:hover {
		text-decoration: underline;
	}
}
.view-text {
    width: 100%;

	p {
		margin-top: 0;

		&:not(:last-child) {
			margin-bottom: 24px;
		}
		
		&:last-child {
			margin-bottom: 0;
		}

		*:not(a) {
			color: inherit !important;
    		font: inherit !important;
		}
	}

	ul, ol {
		margin-top: 0;
		line-height: rem(24);
		letter-spacing: rem(0.25);
		
		li {
			margin-bottom: rem(5);
		}
	}
}

/**
 * Headers styles
 */
.view-title {
	color: #000;

	&--h1 {
		font-family: 'Palatino';
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		
		@media (min-width: 1240px) {
			margin: 0 0 120px 0;
			font-size: 64px;
		}

		@media (max-width: 1239px) and (min-width: 525px) {
			margin: 0 0 60px 0;
			font-size: 48px;
		}
		
		@media (max-width: 524px) {
			margin: 0 0 48px 0;
			font-size: 38px;
		}
	}

	&--h2 {
		color: #050505;
		font-family: 'Palatino';
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-transform: uppercase;

		@media (min-width: 1240px) {
			margin: 0 0 62px 0;
			font-size: 50px;
		}

		@media (max-width: 1239px) and (min-width: 525px) {
			margin: 0 0 24px 0;
			font-size: 34px;
		}

		@media (max-width: 524px) {
			margin: 0 0 24px 0;
			font-size: 28px;
		}
	}

	&--h3 {
		color: #040404;
		font-family: 'Palatino';
		font-size: 30px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-transform: uppercase;
		margin: 0 0 30px 0;
	}

	&--h4 {
		margin: 0 0 15px 0;
		font-family: 'Montserrat';
		font-weight: 600;

		@media (min-width: 991px) {
			font-size: 18px;
		}
	
		@media (max-width: 990px) {
			font-size: 16px;
			line-height: 18px;
		}
	}

	&--white {
		color: #ffffff;
	}
}

p.p-captcha {
	font-size: rem(14);
	color: #333;
	margin-bottom: 100px;

	a {
		color: $green;
	}
}

.grecaptcha-badge {
	visibility: hidden;
	user-select: none;
	pointer-events: none;
}

.ta {

	&-center {
		text-align: center;
	}

	&-right {
		text-align: right;
	}
}