.view-artist {

    &__years {
        font-family: 'Palatino';
        font-weight: 700;
        
        @media (min-width: 1240px) {
            font-size: 50px;
        }

        @media (max-width: 1239px) {
            font-size: 18px;
        }
    }
    
    &__error {
        display: none;
    }
}

.view-artists__error {
    display: none;
}