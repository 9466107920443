.dilivery {
    display: flex;
    justify-content: space-between;
    
    @media (min-width: 881px) {
        margin: 0 -25px;
    }

    @media (max-width: 880px) {
        flex-direction: column;
        margin-bottom: -40px;
    }

    > div {
        
        @media (min-width: 881px) {
            min-height: 75vh;
            padding: 80px 50px 280px 50px;
            width: calc(50% - 25px);
        }
    
        @media (max-width: 880px) {
            padding: 80px 50px 180px 50px;
            width: 100%;
            margin-bottom: 40px;
        }

        &:nth-child(1) {
            @media (min-width: 881px) {
                background: url('../image/a1a/a2.svg') center top no-repeat;
                background-size: 105%;
            }
            @media (max-width: 880px) {
                background: url('../image/a1a/a2.svg') center 75% no-repeat;
                background-size: cover;
            }
        }

        &:nth-child(2) {
            @media (min-width: 881px) {
                background: url('../image/a1a/a3.svg') center top no-repeat;
                background-size: 105%;
            }
            @media (max-width: 880px) {
                background: url('../image/a1a/a3.svg') center 75% no-repeat;
                background-size: cover;
            }
        }
    }

    h2 {
        margin: 0 0 46px 0;
        color: #050505;
        font-family: 'Palatino';
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
    }

    strong {
        display: inline-block;
        padding-bottom: 30px;
        color: #050505;
        font-family: 'Palatino';
        font-size: 40px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        margin-bottom: 24px !important;
    }
}