.view-filter {
    
    &__field {
        border: none;
        color: #050505;
        font-size: 14px;
        width: 100%;

        @include placeholder(#050505);
        @include placeholder-on-focus();
    }
}

.price_fields {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    > div:last-child input {
        text-align: right;
    }
}

.prdctfltr_checkboxes label {
    position: relative;
    display: block;
    margin: 0 0 20px 0;
    cursor: pointer;
    font-size: 14px;
}

.prdctfltr_checkboxes label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}

.prdctfltr_checkboxes label input ~ span::after {
    content: '';
    position: absolute;
    top: 4.5px;
    left: 7px;
    width: 4px;
    height: 10px;
    border-bottom: 3px solid #00A651;
    border-right: 3px solid #00A651;
    border-radius: 2px;
    transform: rotate(45deg);
    opacity: 0;
    transition: 200ms ease-in-out;
}

.prdctfltr_checkboxes label input:checked ~ span::after {
    opacity: 1;
}

.prdctfltr_checkboxes label > span {
    position: relative;
    display: flex;
}

.prdctfltr_checkboxes label > span::before {
    content: '';
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin: 2px 20px 0 0;
    border: 2px solid #050505;
    border-radius: 4px;
    transition: 200ms ease-in-out;
}

.prdctfltr_checkboxes label > span span {
    flex-shrink: 0;
    padding: 0 0 0 10px;
    margin-right: auto;
}

.prdctfltr_checkboxes label:hover > span::before {
    border-color: #00A651;
}

.pf_more {
    margin: 20px 0 0 0;
    font-size: 14px;
    font-weight: 600;
    transition: 200ms ease-in-out;
    cursor: pointer;
}

.pf_more:hover {
    color: #00A651;
}


.pf_more ~ label {
    display: none;
}