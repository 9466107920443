.view-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 1050;
    overflow: auto;
    transform: translate3d(0,0,0);
    transition: opacity .3s ease, transform .5s ease;
    will-change: opacity, transform;

    .view-feedbac__images {
        padding: 0;
        margin: 25px -16px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }
    .view-feedbac__images li {
        display: flex;
        align-items: center;
        margin: 16px 0;
        width: 50%;
        justify-content: space-between;
    padding: 0 16px;}
    .view-feedbac__images img {
        width: 62px;
    }
    .js-remove-file-form {
        margin-right: 15%;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 767px) {
        display: block !important;

        .view-form__textarea {
            margin: 0 0 20px 0 !important;
        }
    }

    &-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.4);
        transition: opacity .3s ease;
        opacity: 0;
        visibility: hidden;
        z-index: 1049;
    }
    
    &.is-active {
        opacity: 1;
        visibility: visible;

        .view-popup {

            &__wrapper {
                transform: translate3d(0,0,0);
            }
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: opacity .2s ease,transform .3s ease,visibility 0s .1s,-webkit-transform .3s ease;
        transform: translate3d(0,rem(-50),0);
        will-change: transform,opacity;
    }

    &__content {
        padding: rem(50);
        @media (min-width: 768px) {
            margin: rem(30) auto;
        }

        @media (max-width: 767px) {
            margin: rem(10) auto rem(30) auto;
        }
        background-color: #fff;
        box-shadow: 0 5px 15px rgba(255,255,255,.15);

        .view-text {
            margin-bottom: rem(40);

            .link {

                &::after {
                    transform: scaleX(0);
                }

                &:hover {
                    
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    &.js-price-request {
        max-width: 940px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);

        .view-form__textarea {
            margin-top: 0 !important;
        }

        .view-popup__content {
            max-width: 940px;

            p.p-captcha {
                margin-bottom: 40px;
            }
        }

        @media (max-width: 767px) {
            .view-form__group {
                margin-bottom: 10px !important  ;
            }

            .view-text {
                margin-bottom: 18px;
                font-size: 12px;
            }
        }
    }


    &.js-offer-request {
        max-width: 1440px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 16px;
        padding-right: 16px;

        .view-popup__content {
            max-width: 1440px;

            p.p-captcha {
                margin-bottom: 40px;
            }
        }

        .view-form__textarea {
            margin-top: 0;
        }

        @media (max-width: 767px) {
            .view-form__group {
                margin-bottom: 10px !important  ;
            }

            .view-text {
                margin-bottom: 18px;
                font-size: 12px;
            }
            .view-title--h2 {
                margin: 0 0 16px 0 !important;
                font-size: 18px !important;
            }


            p.p-captcha {
                font-size: 10px;
                margin-bottom: 18px;
                line-height: 12px;
            }
        }
    }

    &__close {
        position: absolute;
        margin-left: auto;
        cursor: pointer;
        z-index: 2;
        
        @media (min-width: 768px) {
            top: rem(60);
            right: rem(60);
            margin-right: rem(-30);
        }

        @media (max-width: 767px) {
            top: 30px;
            right: 15px;
        }

        svg {
            width: rem(30);
            height: rem(30);
            transition: transform 0ms;

            #Path_25 {
                fill: #040404;
                transition: fill 300ms ease;
            }
        }

        &:hover {

            svg {
                transition: all 300ms ease;
                fill: $green;
                transform: rotate(180deg);

                #Path_25 {
                    fill: $green;
                }
            }
        }
    }
}

body.is-popup {

    .view-popup-background {
        opacity: 1;
        visibility: visible;
    }
}