.view-zhanrs {
    width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
    display: grid;
    grid-gap: 30px;
    
    @media (min-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 332px 332px 332px 332px;
        grid-template-areas: 
        "a aq aq aw aw"
        "b bq c c cq"
        "b cw g g h"
        "ae ae q q h";
    }
    
    @media (max-width: 1299px) and (min-width: 991px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 280px 280px 280px 280px 280px;
        grid-template-areas: 
        "a a aq aq aw aw"
        "ae ae b b bq bq"
        "c c cq cq cw cw"
        "g g g h h h"
        "q q q q q q";
    }

    @media (max-width: 990px) and (min-width: 600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 280px 280px 280px 280px 280px 280px 280px;
        grid-template-areas: 
        "a a aq aq"
        "aw aw ae ae"
        "b b bq bq"
        "c c cq cq"
        "cw cw g g"
        "h h q q"
        "e e e e";
    }

    @media (max-width: 599px) {
        grid-template-columns: 1fr;
        grid-template-rows: 180px 180px 180px 180px 180px 180px 180px 180px 180px 180px 180px 180px 180px;
        grid-template-areas: 
        "a"
        "aq"
        "aw"
        "ae"
        "b"
        "bq"
        "c"
        "cq"
        "cw"
        "g"
        "h"
        "q"
        "e"
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        
        @media (min-width: 991px) {
            padding-top: 120px;
            padding-left: 40px;
        }

        @media (max-width: 990px) {
            padding-top: 60px;
            padding-left: 20px;
        }

        &__title {
            font-weight: 600;
            color: #000000;
            line-height: normal;
            text-transform: uppercase;
            
            @media (min-width: 2120px) {
                font-size: 38px;
                margin-bottom: 30px;
            }

            @media (max-width: 2119px) {
                font-size: 32px;
                margin-bottom: 26px;
            }

            @media (max-width: 1919px) {
                font-size: 26px;
                margin-bottom: 14px;
            }
        }

        &:nth-child(1) { grid-area: a; }

        &:nth-child(2) { grid-area: aq; }
        &:nth-child(3) { grid-area: aw; }
        &:nth-child(4) { grid-area: ae; }

        &:nth-child(5) { grid-area: b; }

        &:nth-child(6) { grid-area: bq; }

        &:nth-child(7) { grid-area: c; }

        &:nth-child(8) { grid-area: cq; }
        &:nth-child(9) { grid-area: cw; }

        &:nth-child(10) { grid-area: g; }
        &:nth-child(11) { grid-area: h; }
        &:nth-child(12) { grid-area: q; }
        &:nth-child(13) { grid-area: e; }
    }

    img {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;
    }
}