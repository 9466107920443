.view-feedback {

    &__fields {
        display: flex;
        margin: 0 -30px;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .view-form__group {
            padding: 0 30px;
            
            @media (min-width: 768px) {
                width: calc(100% / 3);
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
}