.view-cart {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1050;
    opacity: 0;
    visibility: hidden;
    transition: 500ms ease;

    @media (min-width: 880px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 879px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 767px) {
        .view-title--h4 {
            font-size: 12px !important;
            line-height: 14px !important;
            margin-bottom: 10px !important;
        }
    }

    &.is-active {
        opacity: 1;
        visibility: visible;

        .view-cart__wrapper {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        max-width: rem(960);
        padding: rem(50) rem(40);
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.9);
        transition: 500ms ease;

        @media (max-width: 524px) {
            padding: rem(50px) 18px;
        }
    }

    &__item {
        position: relative;
        display: flex;
        margin: 0 0 rem(20) 0;
        align-items: center;

        &__thumbnail {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 120px;
            width: 120px;
            padding: 11px;
            background-color: rgba(247,247,247,0.5);
            flex-shrink: 0;
            
            @media (max-width: 520px) {
                height: 90px;
                width: 90px;
                padding: 0;
                margin-right: auto;
            }

            img {
                max-width: 100%;
                height: auto;
                max-height: 100%;

            }
        }

        &__title {
            margin-bottom: rem(15) !important;
            transition: 200ms $easing;

            &:hover {
                color: $green;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;

            @media (min-width: 521px) {
                margin-left: 11.5%;
                width: 50%;
            }

            @media (max-width: 520px) {
                margin-left: 10px;
                padding-right: 10px;
            }
        }

        &__brand {
            color: #707070;
            font-size: rem(14);
            font-weight: normal;
            font-weight: 300;
            transition: 200ms $easing;

            &:hover {
                color: $green;
            }

            @media (max-width: 767px) {
                line-height: 12px;
            }
        }

        &__price {
            font-size: rem(18);
            font-weight: 600;
            fill: #060606;
            

            @media (min-width: 521px) {
                margin-right: auto;
            }

            @media (max-width: 520px) {
                font-size: 16px;
            }
        }

        &__remove {
            cursor: pointer;

            &::before {
                display: none;
            }

            @media (max-width: 520px) {
                position: absolute;
                top: 0;
                right: 0;
            }

            svg path#Path_25 {
                transition: 300ms $easing;
            }

            &:hover {

                svg path#Path_25 {
                    fill: $green;
                }
            }
        }
    }

    &__total {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin: rem(50) 0;
        text-align: right;

        @media (max-width: 520px) {
            justify-content: space-between;
        }

        &__label {
            margin-bottom: rem(-5) !important;
            font-size: rem(20px) !important;
            text-transform: none !important;
        }

        &__price {
            font-size: rem(30);
            margin-left: rem(50);
            font-weight: 600;

            @media (max-width: 520px) {
                font-size: 17.5px;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 380px) {
            flex-direction: column;

            .button {
                margin-bottom: rem(10);
            }
        }
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
            
            path#Path_25 {
                fill: #040404;
                transition: 200ms $easing;
            }

            &:hover {

                path#Path_25 {
                    fill: $green;
                }
            }
        }

        &::before {
            display: none;
        }
    }

    @media (max-width: 520px) {
        .js-close-cart {
            display: none;
        }
    }
}

.blockUI.blockOverlay {
    background-color: rgba(255, 255, 255, 0.9) !important ;
}